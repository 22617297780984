import { makeStyles, Typography } from "@material-ui/core"
import React from "react"
import PropTypes from "prop-types"
import { getLevelColor } from "../../../utils/racqy-helper"

const useStyles = makeStyles(theme => ({
  circle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    marginRight: "5px",
    width: props => props.size,
    height: props => props.size,
    color: theme.palette.primary.contrastText,
    fontWeight: 700,
    fontSize: props => props.size * 0.5,
  },
}))

const NumberCircle = ({ number, size }) => {
  const classes = useStyles({ size })
  const nums = []

  if (number.length > 1) {
    for (let i = number[0]; i <= number[1]; i++) {
      nums.push(i)
    }
  } else {
    nums.push(number[0])
  }

  return (
    <>
      {nums.map(item => {
        let level = getLevelColor(item)

        return (
          <div style={{ backgroundColor: level.bg }} className={classes.circle}>
            <Typography style={{ color: "black", fontSize: "14px" }}>
              {item}
            </Typography>
          </div>
        )
      })}
    </>
  )
}

NumberCircle.propTypes = {
  number: PropTypes.arrayOf(PropTypes.number).isRequired,
  size: PropTypes.number.isRequired,
}

export default NumberCircle
